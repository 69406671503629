import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section, {SectionHeader} from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import {DivListItemCol, DivListItemRow} from "../components/DivListItem";
import InternalLink from "../components/InternalLink";
import UnorderedList from "../components/UnorderedList";
import ExternalLink from "../components/ExternalLink";
import DivWithText from "../components/DivWithText";

function ServicesPage() {
    return (
        <LayoutGeneral>
            {/*<SEO*/}
            {/*    keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Referral Resources`]}*/}
            {/*    title="Referral Resources"*/}
            {/*    description="Referral Resources for Agile Psychiatry and Brian Nahas, MD"*/}
            {/*/>*/}

            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Referral Resources</h2>
                        <TextParagraph className="mb-8 italic">
                            This page contains referral resources for providers referring prospective patients to Agile
                            Psychiatry. <span className="font-bold">If you are not a provider or staff member of a clinic,
                            please discontinue use of this page and continue to our <InternalLink to="/services">Services</InternalLink> page.</span>
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            Thank you for considering Agile Psychiatry for your patients&apos; mental health needs! Here you
                            will find some information that may be helpful for referrals. If you have any questions,
                            feel free to call the clinic at the number below.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv className="w-full">
                        <div className="m-4 flex flex-col text-xl">
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2" >
                                    <InternalLink to="#contact">
                                        Contact Information
                                    </InternalLink>
                                </DivListItemCol>
                                <DivListItemCol className="md:w-1/2">
                                    <InternalLink to="#contraindications">
                                        Relative Contraindications
                                    </InternalLink>
                                </DivListItemCol>
                                <DivListItemCol className="md:w-1/2" >
                                    <InternalLink to="#summarytemplate">
                                        Referral Template
                                    </InternalLink>
                                </DivListItemCol>
                            </DivListItemRow>
                        </div>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader name="contact">Contact Information</SectionHeader>
                        <TextParagraph className="mb-8">
                            Please do not fax records until the patient has been confirmed for an appointment.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            Phone: <a href="tel:5123376684">(512) 337-6684</a> ( Press 2 after connecting )
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            Fax: (216)208-1379
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv className="w-full">
                        <SectionHeader name="contraindications">Relative Contraindications</SectionHeader>
                        <TextParagraph className="mb-8">
                            Agile Psychiatry is an online-only psychiatry clinic with Brian Nahas, MD as the sole
                            practitioner. Hence, there are some relative contraindications in terms of acuity or treatment
                            requirements to consider before referring a patient.
                        </TextParagraph>
                        <DivWithText className="mb-8">
                            <UnorderedList className="ml-6">
                                <li>Recent hospitalization, frequent hospitalization, and/or high acuity</li>
                                <li>Need for controlled substances (stimulants, benzodiazepines, etc)</li>
                                <li>Need for psychiatric specialization (prominent substance use, eating disorder, cognitive impairment, etc)</li>
                                <li>Technical / Internet limitations</li>
                            </UnorderedList>
                        </DivWithText>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <SectionHeader name="summarytemplate">Referral Template for Visit Summary</SectionHeader>
                        <TextParagraph className="mb-8">
                            The following text is provided for convenience and is provided &quot;as is&quot;. Agile Psychiatry PLLC
                            provides no warranty, support, or guarantee regarding the text below. Individual providers
                            should make a determination whether or not to use this text in a patient record based on their individual
                            assessment of the patient and associated clinical judgement on the text&apos;s appropriateness. Permission
                            to the use the following three paragraphs in fixed-width font is granted under the Creative Commons <ExternalLink to="https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode">
                            Attribution-NonCommercial-ShareAlike</ExternalLink> license.
                        </TextParagraph>
                        <p className="font-mono md:text-lg mb-8">
                            You have been referred to Brian Nahas, MD at Agile Psychiatry for psychiatric care. Dr. Nahas
                            provides psychiatric care to patients via telemedicine across Texas, so you can see him
                            from the comfort of your own home. Please visit https://www.agilepsychiatry.com
                            to request an appointment. If you have additional questions about Agile Psychiatry
                            beyond what is available on the website, you can contact their clinic at (512) 337-6684.
                        </p>
                        <p className="font-mono md:text-lg mb-8">
                            We can only refer you, we cannot guarantee an appointment. After requesting an appointment,
                            Dr. Nahas will contact you to make sure that Agile Psychiatry is a good fit for your needs.
                            Agile Psychiatry does not accept insurance directly, but does provide documentation to submit claims
                            for &quot;Out of Network&quot; benefits. See the &quot;Services&quot;
                            page on Agile Psychiatry&apos;s website for more information.
                        </p>
                        <p className="font-mono md:text-lg mb-8">
                            If you ever have a psychiatric emergency (for example, having thoughts of hurting yourself or
                            someone else), do not wait for an appointment somewhere, call 911 or proceed to the nearest
                            emergency room immediately.
                        </p>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <TextDiv className="pb-10">
                    </TextDiv>
                </SectionRow>
            </Section>
        </LayoutGeneral>
    );
}


export default ServicesPage;
